<template>
  <b-modal id="query-modal-data-edit" title="查询数据编辑" ok-title="提交" centered hide-footer no-close-on-backdrop
           cancel-title="取消" size="xl">
    <b-row>
      <b-col cols="12">
        <b-alert v-model="table_is_view" variant="warning" class="text-center" style="padding: 1rem" fade>
          <feather-icon class="mr-50" icon="AlertTriangleIcon" size="18"/>
          <span style="position: relative;top: 1px">目标表为数据库视图，无法进行编辑</span>
        </b-alert>
        <b-card>
          <h5>查询结果
            <b-form-checkbox class="d-inline-block mx-1" size="small" switch v-model="table_data_show_all">
              显示全部
            </b-form-checkbox>
            <b-button
                :disabled="table_is_view"
                class="d-inline-block float-right"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                size="sm" variant="primary" @click="addRow()"
            >
              <feather-icon class="mr-50" icon="PlusIcon"/>
              新增数据
            </b-button>
          </h5>
          <div style="height: 60vh">
            <b-table
                :empty-text="$t('No Data')"
                :fields="table_fields"
                :items="table_data"
                class="m-0"
                bordered
                responsive
                small
                sticky-header
                :style="'height: '+(table_data_show_all?'calc(100% - 2rem)':'auto')"
            >
              <template #cell(index)="row">
                <span>{{ row.index + 1 }}</span>
              </template>
              <template #cell(action)="row">
                <div class="flex flex-centered" style="width: 4rem">
                  <b-button
                      :disabled="table_is_view"
                      class="btn-icon mr-50"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      size="sm" variant="success" @click="editRow(row.item)"
                      style="padding: 0.3rem"
                  >
                    <feather-icon icon="EditIcon"/>
                  </b-button>
                  <b-button
                      :disabled="table_is_view"
                      class="btn-icon" size="sm" variant="danger" @click="deleteRow(row.item)"
                      style="padding: 0.3rem"
                  >
                    <feather-icon icon="TrashIcon"/>
                  </b-button>
                </div>
              </template>
            </b-table>
            <div class="mt-50 text-right">
              <span class="ml-50" style="width: 10rem">
                <span v-if="table_data_show_all" class="text-warning">{{ '共' + query_data.total + '条数据' }}</span>
                <span v-else class="text-warning">仅展示前5条</span>
              </span>
            </div>
          </div>
          <b-modal
              v-model="target_table_data_modal.show"
              :title="(target_table_data_modal.type==='add'?'新增':(target_table_data_modal.type==='edit'?'编辑':'删除'))+'数据'"
              ok-title="提交" centered no-close-on-backdrop cancel-title="取消" size="lg"
              button-size="sm"
              @ok="target_table_data_submit()"
          >
            <b-row>
              <b-col cols="10">
                <b-form-group
                    label-cols="3"
                    label="主键 :"
                    label-align="right"
                    label-for="target-table-primary-key"
                >
                  <v-select
                      id="target-table-primary-key"
                      v-model="target_table_data_modal.data.primary_key"
                      :clearable="false"
                      :options="query_data.fields"
                      required
                      class="rounded"
                      placeholder="请选择主键字段名"
                  >
                  </v-select>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="10">
                <b-form-group
                    v-for="(item,itemKey) in target_table_data_modal.data.form_data"
                    :label="itemKey"
                    label-cols="3"
                    label-align="right"
                    :label-for="'target-table-'+itemKey"
                    :key="'target-table-'+itemKey"
                    v-show="target_table_data_modal.type!=='delete'||itemKey===target_table_data_modal.data.primary_key"
                >
                  <b-form-input
                      :disabled="itemKey===target_table_data_modal.data.primary_key"
                      :id="'target-table-'+itemKey"
                      required
                      v-model="target_table_data_modal.data.form_data[itemKey]"
                      placeholder="请输入字段值"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
          </b-modal>
        </b-card>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import {computed, reactive, toRefs} from "@vue/composition-api";
import store from "@/store";
import {query_temp_data} from "@/views/panel-group/defaultSettings";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import {showToast} from "@/libs/utils/showToast";
import {showConfirm} from "@/libs/utils/showConfirm";

export default {
  name: "PanelModalDataEdit",
  props: ["query-key"],
  directives: {
    Ripple,
  },
  components: {
    vSelect,
  },
  setup(props) {
    const {queryKey} = props;

    const event = reactive({
      query_data: computed(() => {
        const data = store.state["panel-group"]["query-data-list"][queryKey];
        console.log('data', data)
        return data ? data : query_temp_data;
      }),
      table_is_view: computed(() => {
        return !!event.query_data.sql_explain?.table_is_view;
      }),
      table_data_show_all: false,
      table_data: computed(() => {
        const data = store.state["panel-group"]["query-data-list"][queryKey].temp_data || []
        return event.table_data_show_all ? data : data.slice(0, 5);
      }),
      table_fields: computed(() => {
        let fields = [{
          key: "index",
          label: "#",
        }];
        event.query_data.fields.forEach((field) => {
          fields.push({
            key: field,
            label: field,
          });
        });
        fields.push({
          key: "action",
          label: "操作",
        });
        return event.query_data.fields.length > 0 ? fields : [];
      }),
      target_table_data_modal: {
        show: false,
        type: "add",
        data: {
          primary_key: "",
          form_data: {},
        },
      }
    })

    //初始化行数据
    const initRowData = () => {
      let form_data = {};
      event.query_data.fields.forEach((field) => {
        form_data[field] = null;
      });
      return form_data;
    }

    //编辑行
    const editRow = (row) => {
      event.target_table_data_modal.show = true;
      event.target_table_data_modal.type = "edit";
      event.target_table_data_modal.data.form_data = row;
    }

    //新增行
    const addRow = () => {
      event.target_table_data_modal.show = true;
      event.target_table_data_modal.type = "add";
      event.target_table_data_modal.data.form_data = initRowData();
    }

    //删除行
    const deleteRow = (row) => {
      event.target_table_data_modal.show = true;
      event.target_table_data_modal.type = "delete";
      event.target_table_data_modal.data.form_data = row;
    }

    //提交数据（新增、编辑）
    const target_table_data_submit = () => {
      const type = event.target_table_data_modal.type;
      const query_id = event.query_data.query_id
      if (!query_id) {
        showToast(null, "此查询不存在", "warning");
        return false
      }
      if (type === 'add') {
        store.dispatch("panel-group/addQueryTargetTableData", {
          query_id: query_id,
          data: event.target_table_data_modal.data.form_data,
        }).then(() => {
          showToast(null, "新增成功", "目标表中已成功插入数据");
        }).catch(error => {
          showToast(error);
        })
      }
      if (type === 'edit') {
        store.dispatch("panel-group/updateQueryTargetTableData", {
          query_id: query_id,
          data: event.target_table_data_modal.data.form_data,
        }).then(() => {
          showToast(null, "修改成功", "目标表中已成功修改数据");
        }).catch(error => {
          showToast(error);
        })
      }
      if (type === 'delete') {
        const primary_key = event.target_table_data_modal.data.primary_key;
        if (!primary_key) {
          showToast(null, "提示", "未设置主键字段，无法删除数据");
          return false
        }
        showConfirm('是否确认删除此数据？删除后无法恢复！', () => {
          store.dispatch("panel-group/deleteQueryTargetTableData", {
            query_id: query_id,
            data: event.target_table_data_modal.data.form_data[primary_key],
          }).then(() => {
            showToast(null, "修改成功", "目标表中已成功修改数据");
          }).catch(error => {
            showToast(error);
          })
        })

      }
    }


    return {
      ...toRefs(event),
      editRow,
      addRow,
      deleteRow,
      target_table_data_submit
    }
  }
}
</script>

<style scoped>
.b-table-sticky-header {
  max-height: 100%;
}
</style>