import { render, staticRenderFns } from "./GroupTopbar.vue?vue&type=template&id=7e0c56f1&scoped=true"
import script from "./GroupTopbar.vue?vue&type=script&lang=js"
export * from "./GroupTopbar.vue?vue&type=script&lang=js"
import style0 from "./GroupTopbar.vue?vue&type=style&index=0&id=7e0c56f1&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7e0c56f1",
  null
  
)

export default component.exports