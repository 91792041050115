<template>
	<!-- modal login-->
	<b-modal
		id="share-modal"
		centered
		hide-footer
		no-close-on-backdrop
		title="页面分享"
	>
		<b-row>
			<b-col cols="4">
				<vue-qr
					v-if="!!link"
					:logoScale="80"
					:logoSrc="require('@/assets/images/logo/logo.png')"
					:margin="0"
					:text="link"
					class="share-qr"
				></vue-qr>
				<b-img
					v-else
					:src="require('@/assets/images/errors/404.png')"
					style="width: 132px; height: 132px"
				/>
			</b-col>
			<b-col cols="8">
				<b-form-group>
					<label>权限</label>
					<b-form-radio-group v-model="permission.editable" size="sm">
						<b-form-radio :value="false">只读</b-form-radio>
						<b-form-radio :value="true">允许编辑</b-form-radio>
					</b-form-radio-group>

					<label class="mt-1">链接</label>
					<b-form-textarea
						v-model="link"
						disabled
						rows="2"
						size="sm"
					/>
				</b-form-group>
			</b-col>
		</b-row>

		<div class="text-center my-1">
			<b-button class="mr-3" variant="success"> 复制二维码</b-button>
			<b-button
				v-clipboard:copy="link"
				v-clipboard:error="onError"
				v-clipboard:success="onCopy"
				variant="primary"
			>
				复制链接
			</b-button>
		</div>
	</b-modal>
</template>

<script>
import { BButton } from "bootstrap-vue";
import VueQr from "vue-qr";
import Ripple from "vue-ripple-directive";
import { showToast } from "@/libs/utils/showToast";

export default {
	name: "ShareModal",
	directives: {
		Ripple,
	},
	components: {
		BButton,
		VueQr,
	},
	data() {
		return {
			permission: {
				editable: false,
			},
			link: window.location.href,
		};
	},
	methods: {
		onCopy(event) {
			console.log("e", event);
			showToast(null, "复制成功", "链接已经成功复制到剪切板");
		},
		onError(error) {
			showToast(error, "复制失败");
		},
	},
};
</script>

<style>
img.share-qr {
	background-color: #fff;
	box-sizing: border-box;
	height: 132px;
	padding: 4px;
	width: 132px;
}

#share-modal .card-body {
	padding: 0.2rem;
}

#share-modal .custom-radio.b-custom-control-sm .custom-control-label::before,
#share-modal .input-group-sm .custom-radio .custom-control-label::before,
#share-modal .custom-radio.b-custom-control-sm .custom-control-label::after,
#share-modal .input-group-sm .custom-radio .custom-control-label::after {
	left: 0;
}
</style>
