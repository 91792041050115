<template>
	<!-- modal login-->
	<b-modal
		id="query-modal"
		v-model="show"
		:title="query_modal.query_id ? '编辑查询（查询ID：' + query_modal.query_id + '）' : '添加新查询'"
		button-size="sm"
		cancel-title="取消"
		cancel-variant="outline-primary"
		centered
		no-close-on-backdrop
		ok-title="确定"
		size="lg"
		@ok.prevent="submitQuery"
	>
		<app-timeline>
			<app-timeline-item :variant="query_modal.query_id ? 'warning' : 'success'">
				<h5>查询类型</h5>
				<b-form-group label-for="query_type">
					<v-select v-model="query_modal.query_type" :clearable="false" :options="query_type_list" :reduce="(text) => text.value" class="rounded" label="text" />
				</b-form-group>
			</app-timeline-item>

			<app-timeline-item :variant="query_modal.query_id ? 'warning' : 'success'">
				<h5>查询名称</h5>
				<b-form-group label-for="query_name">
					<b-form-input id="query_name" v-model="query_modal.query_name" class="rounded" />
				</b-form-group>
			</app-timeline-item>

			<template v-if="query_modal.query_type === 1">
				<app-timeline-item :variant="query_modal.query_id ? 'warning' : 'success'">
					<h5>数据源配置</h5>
					<div class="text-right">
						<b-button v-b-modal.datasource-modal v-ripple.400="'rgba(255, 255, 255, 0.15)'" size="sm" variant="success" @click="datasource_modal_show(null)">
							<feather-icon class="mr-50" icon="PlusIcon" />
							新增数据库
						</b-button>
						<b-button v-show="!!query_modal.datasource_id" v-b-modal.datasource-modal v-ripple.400="'rgba(255, 255, 255, 0.15)'" class="ml-1" size="sm" variant="warning" @click="datasource_modal_show(query_modal.datasource_id)">
							<feather-icon class="mr-50" icon="EditIcon" />
							编辑数据库
						</b-button>
					</div>
					<datasource-settings-modal :key="'panel-datasource-modal' + query_modal.datasource_id"></datasource-settings-modal>
					<b-form-group label="数据库" label-for="query_database">
						<v-select id="query_database" v-model="query_modal.datasource_id" :clearable="false" :options="datasource_list" :reduce="(text) => text.value" class="rounded" label="text" />
					</b-form-group>

					<b-form-group label="数据表" label-for="query_table">
						<v-select id="query_table" v-model="query_modal.table" :clearable="false" :options="table_list" :reduce="(text) => text.value" class="rounded" label="text" />
					</b-form-group>
					<div class="small text-right">
						<span v-if="table_alert_text">{{ table_alert_text }}</span>
						<span v-else>
							当前数据表数据量
							<span v-if="table_count < 2000000" class="text-primary">{{ table_count }}</span>
							<span v-else>
								已超出
								<span class="text-warning"> 2000000 </span>
								，为避免查询等待时间过长，建议更换为其他数据表
							</span>
						</span>
					</div>
				</app-timeline-item>

				<app-timeline-item :variant="query_modal.query_id ? 'warning' : 'success'">
					<h5>时间区间查询</h5>
					<b-row>
						<b-col cols="6">
							<b-form-group label="时间字段" label-for="time_query_field">
								<v-select id="time_query_fields" v-model="query_modal.time_query_field" :clearable="false" :options="field_list" :reduce="(text) => text.value" class="rounded" label="text" />
							</b-form-group>
						</b-col>
						<b-col cols="6">
							<b-form-group label="查询类型" label-for="time_query_type">
								<v-select
									id="time_query_type"
									v-model="query_modal.time_query_type"
									:clearable="false"
									:options="[
										{
											text: '精确区间',
											value: 1,
										},
										{
											text: '最近区间',
											value: 2,
										},
										{
											text: '语意区间',
											value: 3,
										},
									]"
									:reduce="(text) => text.value"
									class="rounded"
									label="text"
								/>
							</b-form-group>
						</b-col>
						<!--精确时间区间-->
						<template v-if="query_modal.time_query_type === 1">
							<b-col cols="6">
								<b-form-group label="起始时间（yyyy-mm-dd hh:mm:ss）" label-for="time_query_start">
									<b-form-input id="time_query_start" v-model="query_modal.time_query_start" class="rounded" />
								</b-form-group>
							</b-col>
							<b-col cols="6">
								<b-form-group label="结束时间（yyyy-mm-dd hh:mm:ss）" label-for="time_query_end">
									<b-form-input id="time_query_end" v-model="query_modal.time_query_end" class="rounded" />
								</b-form-group>
							</b-col>
						</template>
						<!--模糊时间区间-->
						<template v-else-if="query_modal.time_query_type === 2">
							<b-col class="text-center" cols="2">
								<span style="line-height: 2.5rem">最近</span>
							</b-col>
							<b-col cols="7">
								<b-form-group label-for="time_query_length">
									<b-form-input id="time_query_length" v-model="query_modal.time_query_length" class="rounded" type="number" />
								</b-form-group>
							</b-col>
							<b-col cols="3">
								<b-form-group label-for="time_query_unit">
									<v-select id="time_query_unit" v-model="query_modal.time_query_unit" :clearable="false" :options="time_query_unit_list" :reduce="(text) => text.value" class="rounded" label="text" />
								</b-form-group>
							</b-col>
						</template>
						<template v-else>
							<b-col class="text-center" cols="2">
								<span style="line-height: 2.5rem">区间</span>
							</b-col>
							<b-col cols="4">
								<b-form-group label-for="time_query_range">
									<v-select id="time_query_range" v-model="query_modal.time_query_range" :clearable="false" :options="time_query_range_list" :reduce="(text) => text.value" class="rounded" label="text" />
								</b-form-group>
							</b-col>
						</template>
					</b-row>
				</app-timeline-item>
			</template>

			<template v-if="query_modal.query_type === 1">
				<app-timeline-item :variant="query_modal.query_id ? 'warning' : 'success'">
					<h5>其他筛选条件</h5>

					<p class="text-right">
						<b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" size="sm" variant="success" @click="query_map_add">
							<feather-icon class="mr-50" icon="PlusIcon" />
							新增条件
						</b-button>
					</p>
					<h5 v-if="!query_modal.query_map.length" class="text-center mt-5">未添加筛选条件</h5>
					<app-timeline v-else>
						<app-timeline-item v-for="(query_map_item, query_map_index) in query_modal.query_map" :key="'query_map_' + query_map_index" variant="info">
							<h6>条件 {{ query_map_index + 1 }}</h6>
							<b-button class="position-absolute" size="sm" style="right: 0; top: 0" variant="flat-danger" @click="query_map_del(query_map_index)">
								<feather-icon class="mr-50" icon="XIcon" />
								删除条件
							</b-button>

							<b-row>
								<b-col cols="6">
									<b-form-group :label-for="'query_map_field_' + query_map_index + 1" label="条件字段">
										<v-select :id="'query_map_field_' + query_map_index + 1" v-model="query_map_item.field" :clearable="false" :options="field_list" :reduce="(text) => text.value" class="rounded" label="text" />
									</b-form-group>
								</b-col>
								<b-col cols="6">
									<b-form-group :label-for="'query_map_operator_' + query_map_index + 1" label="比较符">
										<v-select :id="'query_map_operator_' + query_map_index + 1" v-model="query_map_item.operator" :clearable="false" :options="panel_operator_list" :reduce="(text) => text.value" class="rounded" label="text" />
									</b-form-group>
								</b-col>
								<b-col v-if="!['NULL', 'NOT NULL'].includes(query_map_item.operator)" cols="12">
									<b-form-group :label="'比较值' + query_map_value_alert_text_fn(query_map_item.operator)" :label-for="'query_map_value_' + query_map_index + 1" class="mb-0">
										<b-form-input :id="'query_map_value_' + query_map_index + 1" v-model="query_map_item.value" placeholder="若此项为空，比较值为空字符串" />
									</b-form-group>
								</b-col>
							</b-row>
						</app-timeline-item>
					</app-timeline>
				</app-timeline-item>
			</template>

			<template v-else-if="query_modal.query_type === 2"> Api数据源配置-开发中</template>
			<template v-else>数据文件（csv，excel）数据源配置-开发中</template>
		</app-timeline>
	</b-modal>
</template>

<script>
	import vSelect from "vue-select";
	import VuePerfectScrollbar from "vue-perfect-scrollbar";
	import AppTimeline from "@/@core/components/app-timeline/AppTimeline.vue";
	import AppTimelineItem from "@/@core/components/app-timeline/AppTimelineItem.vue";
	import BCardActions from "@/@core/components/b-card-actions/BCardActions.vue";
	import Ripple from "vue-ripple-directive";
	import DatasourceSettingsModal from "../DatasourceModal/DatasourceModal.vue";
	import useQueryModal from "./QueryModalUse";

	export default {
		name: "QueryModal",
		directives: {
			Ripple,
		},
		components: {
			AppTimeline,
			AppTimelineItem,
			BCardActions,
			DatasourceSettingsModal,
			VuePerfectScrollbar,
			vSelect,
		},
		setup(props, ctx) {
			const {
				show,
				time_query_unit_list,
				time_query_range_list,
				query_type_list,
				panel_operator_list,
				query_list,
				table_list,
				table_count,
				field_list,
				datasource_list,
				query_modal,
				table_alert_text,
				query_map_add,
				query_map_del,
				query_map_value_alert_text_fn,
				fetchDatasourceList,
				datasource_modal_show,
				submitQuery,
			} = useQueryModal();
			fetchDatasourceList();
			return {
				show,
				time_query_unit_list,
				time_query_range_list,
				panel_operator_list,
				query_type_list,
				query_list,
				table_list,
				table_count,
				field_list,
				datasource_list,
				query_modal,
				table_alert_text,
				query_map_add,
				query_map_del,
				query_map_value_alert_text_fn,
				datasource_modal_show,
				submitQuery,
			};
		},
	};
</script>

<style>
	/* #query-modal .card {
  margin-bottom: 1rem;
}

#query-modal .card-body {
  padding: 1rem;
}

#query-modal .timeline-item {
  padding-left: 1rem;
} */
</style>
