<template>
  <div>
    <panel-group
      :ref="'panel-group-' + uid"
      :group-uid="uid"
      :show-title="show_title"
    ></panel-group>
  </div>
</template>

<script>
import PanelGroup from "./layout/PanelGroup/PanelGroup";
import { computed, reactive, toRefs } from "@vue/composition-api";
import store from "@/store";
import { routerParams } from "@/libs/utils/routerParams";

export default {
  name: "index",
  components: {
    PanelGroup,
  },
  props: ["group-uid", "show-title"],
  setup(props) {
    const event = reactive({
      uid: computed(() => {
        return (
          props.groupUid ||
          store.state.menus.current.params.uid ||
          routerParams("uid")
        );
      }),
      show_title: computed(() => {
        return props.showTitle !== false;
      }),
    });
    return {
      ...toRefs(event),
    };
  },
};
</script>

<style scoped></style>
