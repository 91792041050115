<template>
	<div class="w-100 h-100">
		<!--		<panel-modal-options-form-->
		<!--			v-if="panel_modal.options_type === 1"-->
		<!--			:item="panel_modal"-->
		<!--			class="w-100 h-100"-->
		<!--		></panel-modal-options-form>-->
		<panel-modal-options-json :key="'panel-options-json' + panel_modal.panel_id" :item="panel_modal" class="w-100 h-100"></panel-modal-options-json>
	</div>
</template>

<script>
	import { reactive, toRefs } from "@vue/composition-api";
	import PanelModalOptionsForm from "./PanelModalOptionsForm";
	import PanelModalOptionsJson from "./PanelModalOptionsJson";

	export default {
		name: "PanelModalParams",
		props: ["item"],
		components: {
			PanelModalOptionsForm,
			PanelModalOptionsJson,
		},
		setup(props) {
			const { item } = props;
			// ------------------------------------------------
			// 引入响应数据
			// ------------------------------------------------
			const event = reactive({
				panel_modal: item,
			});

			return {
				...toRefs(event),
			};
		},
	};
</script>

<style scoped></style>
