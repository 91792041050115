<template>
	<section id="panel-modal-options-json" class="h-100 w-100">
		<b-row>
			<b-col v-show="panel_modal.options_type === 1 || panel_modal.options_type === 3" :cols="panel_modal.options_type === 3 ? 6 : 12">
				<component :is="'panel-component-' + panel_modal.type + '-settings'" :item="panel_modal" class="w-100 no-drag zindex-0"></component>
			</b-col>
			<b-col v-show="panel_modal.options_type === 2 || panel_modal.options_type === 3" :cols="panel_modal.options_type === 3 ? 6 : 12">
				<label>高级配置（代码模式）</label>
				<MonacoEditor :key="randomkey" :code="code" :options="monacoOptions" :theme="$store.state['appConfig']['layout']['skin'] === 'dark' ? 'vs-dark' : 'vs'" style="max-height: 50rem" @codeChange="onCodeChange" @mounted="onInit">
				</MonacoEditor>
				<div class="text-right mt-50">
					<b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" size="sm" variant="warning" @click="getOptions">
						<feather-icon class="mr-50" icon="EyeIcon" />
						预览
					</b-button>
				</div>
			</b-col>
		</b-row>
	</section>
</template>

<script>
	import store from "@/store";
	import { computed, onMounted, reactive, toRefs, watch } from "@vue/composition-api";
	import VuePerfectScrollbar from "vue-perfect-scrollbar";
	import Ripple from "vue-ripple-directive";
	import vSelect from "vue-select";
	import MonacoEditor from "vue-monaco-editor";

	export default {
		name: "PanelModalOptionsJson",
		directives: {
			Ripple,
		},
		components: {
			vSelect,
			MonacoEditor,
			VuePerfectScrollbar,
		},
		props: ["item"],
		setup(props) {
			const { item } = props;

			// ------------------------------------------------
			// 引入页面数据
			// ------------------------------------------------
			let event = reactive({
				monacoOptions: {
					automaticLayout: true,
					folding: true,
					renderIndentGuides: true,
					glyphMargin: false,
				},
				randomkey: 1231231,
				editor: null,
				code: "",
				panel_modal: computed(() => {
					return item;
				}),
			});
			watch(
				event.panel_modal.options,
				(value) => {
					console.log("monaco", value);
					if (!!value) {
						const code_str = optionsToJs(value);
						event.code = code_str;
						if (event.editor) {
							event.editor.setValue(code_str);
						}
					}
				},
				"deep"
			);
			watch(
				() => event.code,
				(value) => {
					if (!!event.editor) {
						event.editor.getAction("editor.action.format").run();
					}
				}
			);
			onMounted(() => {
				setTimeout(() => {
					if (event.editor) {
						event.editor.getAction("editor.action.format").run();
					}
				}, 1000);
			});
			/**
			 * 将json转为js代码字符串
			 * @returns {string} js字符串
			 * @param options
			 */
			const optionsToJs = (options) => {
				let _options = JSON.stringify(options);
				let code_str = "return " + _options;
				return code_str.replaceAll('{"', "{").replaceAll('":', ":").replaceAll(',"', ",").replaceAll("{\n", "{").replaceAll("\n}", "}").replaceAll(",\n", ",").replaceAll(",", ",\n").replaceAll("[{", "[\n{").replaceAll("}]", "}\n]");
			};
			/**
			 * Monaco初始化时执行
			 * @param editor
			 */
			const onInit = (editor) => {
				const code_str = optionsToJs(event.panel_modal.options);
				editor.setValue(code_str);
				event.editor = editor;
				editor.getAction("editor.action.format").run();
			};
			/**
			 * Monaco内容变化时执行
			 * @param editor
			 */
			const onCodeChange = (editor) => {
				event.code = editor.getValue();
			};
			/**
			 * Monaco生成随机码
			 */
			const createRamdomKey = () => {
				return Math.floor((Math.random() * 9 + 1) * 100000);
			};
			/**
			 * 手动获取编辑器的内容
			 */
			const getOptions = () => {
				//将js字符串转为js代码
				let fn = new Function(event.code);
				let value = fn();
				if (!!value) {
					store.commit("panel-group/UPDATE_PANEL_MODAL_OPTIONS", value);
				}
			};

			return {
				...toRefs(event),
				onInit,
				onCodeChange,
				createRamdomKey,
				getOptions,
			};
		},
	};
</script>
<style>
	/* #panel-modal-options-json p,
#panel-modal-options-json .col-form-label {
  font-size: 0.9rem !important;
  margin-bottom: 0.1rem;
}

#panel-modal-options-json .card-body {
  padding: 0.5rem !important;
}

.list-group-item {
  font-size: 0.9rem !important;
}

.monaco-editor.vs-dark .monaco-editor-background {
  background: rgba(0, 0, 0, 0.5) !important;
} */
</style>
